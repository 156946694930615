import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// translations
import { Trans } from "@lingui/macro";

// hooks
import { usePaymentSuccess } from "./use-payment-success";

// icons
import { SuccessIcon } from "../../../assets/icons/SuccessIcon";

// consts
import { ACCOUNT_NUMBER, COMPANY_NAME, IBAN, SORT_CODE } from "../../../config";
import { CART } from "../../../context/cart-provider/CartProvider.consts";
import { PATHS } from "../../../route/route.controls";

// components
import Button from "../../../components/button/Button";
import Spinner from "../../../components/spinner/Spinner";

// styles
import styles from "./PaymentSuccess.module.scss";

export function PaymentSuccess() {
  const navigate = useNavigate();
  const location = useLocation();

  const getCurrencySymbol = (currentCurrency: string): string => {
    switch (currentCurrency) {
      case "EUR":
        return "€";
      case "USD":
        return "$";
      // case "UAH":
      //   return "₴";
      case "GBP":
        return "£";
      default:
        return "€";
    }
  };

  const getQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);

    return {
      amount: searchParams.get("amount") || null,
      currency: searchParams.get("currency") || null,
      isGB: searchParams.get("isGB") || null,
      orderId: searchParams.get("orderId") || null,
    };
  };

  const { amount, currency, isGB, orderId } = getQueryParams();

  const { isPaymentScreenLoading, referenceId } = usePaymentSuccess(orderId);

  useEffect(() => {
    typeof localStorage !== "undefined" && localStorage.removeItem(CART);
  }, [localStorage]);

  if (isPaymentScreenLoading) {
    return (
      <section className={styles.section}>
        <Spinner />
      </section>
    );
  }

  return (
    <section className={styles.section}>
      <div className={styles.mainContent}>
        <SuccessIcon />
        <p className={styles.title}>
          <Trans>Payment successfully completed</Trans>
        </p>

        {referenceId && amount && currency ? (
          <div className={styles.referenceWrapper}>
            <p className={styles.referenceText}>
              <Trans>Reference:</Trans> {referenceId}
            </p>

            <div>
              <p className={styles.referenceText}>
                <Trans>Payment Total:</Trans>{" "}
                <span>
                  {amount} {getCurrencySymbol(currency)}
                </span>
              </p>
            </div>

            <div className={styles.referenceTextWrapper}>
              <p className={styles.title}>
                <Trans>Payee Details</Trans>
              </p>
              <ul className={styles.listWrapper}>
                <li className={styles.listItem}>
                  <p className={styles.referenceText}>
                    <Trans>Payee Name:</Trans> {COMPANY_NAME}
                  </p>
                </li>
                <li className={styles.listItem}>
                  <div className={styles.referenceText}>
                    <Trans>Payee Account Identification:</Trans>

                    {isGB && isGB === "true" ? (
                      <>
                        <p className={styles.referenceText}>
                          <Trans>Account Number:</Trans> {ACCOUNT_NUMBER}
                        </p>
                        <p className={styles.referenceText}>
                          <Trans>Sort code:</Trans> {SORT_CODE}
                        </p>
                      </>
                    ) : (
                      <p className={styles.referenceText}>
                        <Trans>IBAN:</Trans> {IBAN}
                      </p>
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        ) : null}

        <p className={styles.description}>
          <Trans>You can find your purchase in your profile.</Trans>
        </p>
      </div>

      <div className={styles.buttonsWrapper}>
        <Button onClick={() => navigate(PATHS.profile)}>
          <Trans>My profile</Trans>
        </Button>

        <Button
          className={styles.mainButton}
          onClick={() => navigate(PATHS.index)}
        >
          <Trans>To main page</Trans>
        </Button>
      </div>
    </section>
  );
}
